<template>

    <div class="user">
        <h4>
            <span>常用出行人</span>
            <img src="../../assets/signup/add.png" alt="">
            <span class="add" @click="dia=true">添加</span>
        </h4>

        <div class="rbox">
            <div class="box" v-for="(item,i) in list" :key="i">
                <div class="b1 flex-between">
                    <p>
                        <span>{{item.real_name}}</span>
                        <span>{{item.sex | sexshow}}</span>
                        <span>{{item.type_id | oldtype}}</span>
                    </p>
                    <p>{{item.phone}}</p>
                </div>
                <div class="b1">
                    <p>证件类型</p>
                    <p>{{item.card_type | cardtype}}</p>
                </div>
                <div class="b1">
                    <p>证件号码</p>
                    <p>{{item.card_number}}</p>
                </div>
                <div class="b2 b1">
                    <div>
                        <p>出生日期</p>
                        <p>{{item.birthday}}</p>
                    </div>

                    <div class="edit">
                        <img src="../../assets/user/edit2.png" alt="" @click="edit(item)">
                        <img src="../../assets/user/del.png" alt="" @click="del(item.id)">
                    </div>
                </div>
            </div>
        </div>
        <!--  -->
        <el-dialog :visible.sync="dia" title="常用出行人" width="450px" @close="close">
            <diaUserList :form="form" @getlist="getlist"></diaUserList>
        </el-dialog>

    </div>

</template>

<script>
    import { useroutdoorlistAPI, adduseroutdoorAPI, deluseroutdoorAPI } from "@/api/user"
    import  diaUserList from "@/components/com/diaUserList"
    export default {
        components:{
            diaUserList
        },    
        data() {
            return {
                sign: 1,
                list: [],
                addradio: 0,
                bilradio: 0,
                dia: false,
                form: {
                    id: null,
                    real_name: "",
                    sex: "1",
                    card_type: 1,
                    card_number: "",
                    birthday: "",
                    type_id: "1",
                    phone: ""
                },
                //   1=身份证;2=军官证 3=港澳通行证 4=护照 5=回乡证 6=台胞证
                options: [{
                    value: 1,
                    label: '身份证'
                }, {
                    value: 2,
                    label: '军官证'
                }, {
                    value: 3,
                    label: '港澳通行证'
                }, {
                    value: 4,
                    label: '护照'
                }, {
                    value: 5,
                    label: '回乡证'
                }, {
                    value: 6,
                    label: '台胞证'
                }],


            }
        },
        
        created() {
            this.getlist()

        },
        filters: {
            oldtype(t) {
                if (t == 1) {
                    return "成人"
                } else {
                    return "儿童"
                }
            },
            cardtype(t) {

                let arr = ["未知", "身份证", "军官证", "港澳通行证", "护照", "回乡证", "台胞证"]
                let i = parseInt(t)
                return arr[i]
            }
        },
        methods: {
            tourl() {
                this.$router.push({ path: '/user/orderInfo' })
            },
            getlist(dia) {
                console.log(dia)
                this.dia = dia
                // if(!dia){
                    this.close()
                // }
                useroutdoorlistAPI().then(res => {
                    this.list = res.cateList
                })
            },
            del(id) {
                this.$confirm('此操作将删除该出行人信息, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    deluseroutdoorAPI({ id }).then(res => {
                        this.getlist()
                        this.$message("删除成功")
                    })
                    }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
            },
           
            edit(item) {
                this.form = item
                this.form.sex=item.sex+""
                this.form.type_id=item.type_id+""
                this.dia = true
            },
            close() {
                this.form = {
                    id: null,
                    real_name: "",
                    sex: "1",
                    card_type: 1,
                    card_number: "",
                    birthday: "",
                    type_id: "1",
                    phone: ""
                }
            }

        }
    }
</script>

<style lang="scss"  scoped="scoped">
    .user {
        padding: 5px 20px 20px;
        background: #ffffff;
        min-height: 500px;

        h4 {
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            font-weight: 500;
            line-height: 45px;
            display: flex;
            align-items: center;

            img {
                margin-left: 40px;
                margin-right: 10px;
                width: 15px;
            }

            .add {
                color: #FF7C00;
                font-size: 14px;
                cursor: pointer;
            }
        }

        .rbox {
            display: flex;
            flex-wrap: wrap;

            .box {
                width: 470px;
                border: 1px solid #e6e6e6;
                border-radius: 7px;
                margin: 10px;
                padding: 0 10px 10px;

                .b1 {
                    display: flex;

                    margin-top: 10px;

                    p:nth-child(1) {
                        color: #333;
                        margin-right: 10px;
                    }

                    p {
                        span {
                            margin-right: 10px;
                        }
                    }

                    p:nth-child(2) {
                        color: #666;
                        margin-right: 10px;
                    }

                    >div {
                        display: flex;
                        align-items: center;
                    }

                    .edit {
                        img {
                            width: 20px;
                            height:20px;
                            margin: 0 10px;
                        }
                    }
                }

                .b2 {
                    align-items: center;
                    justify-content: space-between;
                }


            }

        }

        .el-dialog__body {
            padding: 0px 20px;
        }

        .fp {
            .el-radio__input.is-checked .el-radio__inner {
                border-color: #ff7c00;
                background: #ff7c00;
            }

            .el-radio__input.is-checked+.el-radio__label {
                color: #ff7c00;
            }

            >p {
                line-height: 40px;
                font-size: 12px;
                color: #999;

                strong {
                    font-size: 16px;
                    margin-right: 15px;
                }
            }

            .fpform {
                >div {
                    display: flex;
                    align-items: center;
                    line-height: 45px;
                    color: #333333;

                    p:nth-child(1) {
                        width: 100px;
                    }

                    input {
                        background: #f6f7f9;
                        border-radius: 4px;
                        width: 287px;
                        height: 35px;

                    }
                }
            }

            .butt {
                p {
                    padding: 0 10px;
                    line-height: 35px;
                    opacity: 1;
                    font-size: 14px;
                    background: #ff7c00;
                    border-radius: 2px;
                    border:1px solid #FF7C00;
                    text-align: center;
                    color: #ffffff;
                    margin: 10px auto;
                    cursor: pointer;
                }
            }
        }
    }
</style>